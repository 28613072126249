import { __ } from '@adac/core-model';
import { ColumnLayout } from '@adac/core-view';
import React, { useState } from 'react';
import styled from 'styled-components';
import { dateFormat } from '../networking/getCases';
import { Filters } from './ListView';

const FilterInput = styled.input`
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledGrid = styled(ColumnLayout)`
  align-items: center;
  ${FilterInput}:nth-child(even) {
    margin: 0;
  }
`;

interface FilterChangeProps {
  handleFilterChange: (key: keyof Filters, value: string) => void;
}

export function DateFilter({ handleFilterChange }: FilterChangeProps) {
  const [toDateFilter, setToDateFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');

  return (
    <StyledGrid ratio='33px min-content'>
      {__('Period from')}:
      <FilterInput
        value={fromDateFilter}
        onChange={(e) => {
          const fromDateFilterValue = e.target.value;
          setFromDateFilter(fromDateFilterValue);
          handleFilterChange('createdAtFrom', fromDateFilterValue);
        }}
        type='text'
        placeholder={dateFormat}
      />
      {__('Period to')}:
      <FilterInput
        value={toDateFilter}
        onChange={(e) => {
          const toDateFilterValue = e.target.value;
          setToDateFilter(toDateFilterValue);
          handleFilterChange('createdAtTo', toDateFilterValue);
        }}
        type='text'
        placeholder={dateFormat}
      />
    </StyledGrid>
  );
}

export function StreetAndHouseNumberFilter({
  handleFilterChange,
}: FilterChangeProps) {
  const [streetFilter, setStreetFilter] = useState('');
  const [houseNumberFilter, setHouseNumberFilter] = useState('');

  return (
    <>
      <FilterInput
        value={streetFilter}
        onChange={(e) => {
          const streetFilterValue = e.target.value;
          setStreetFilter(streetFilterValue);
          handleFilterChange('customerAddressStreet', streetFilterValue);
        }}
        type='text'
        placeholder={__('Street')}
      />
      <FilterInput
        value={houseNumberFilter}
        onChange={(e) => {
          const houseNumberFilterValue = e.target.value;
          setHouseNumberFilter(houseNumberFilterValue);
          handleFilterChange('customerAddressNumber', houseNumberFilterValue);
        }}
        type='text'
        placeholder={__('House number')}
      />
    </>
  );
}

export function ZipAndCityFilter({ handleFilterChange }: FilterChangeProps) {
  const [cityFilter, setCityFilter] = useState('');
  const [zipFilter, setZipFilter] = useState('');

  return (
    <>
      <FilterInput
        value={zipFilter}
        onChange={(e) => {
          const zipFilterValue = e.target.value;
          setZipFilter(zipFilterValue);
          handleFilterChange('customerAddressZip', zipFilterValue);
        }}
        type='text'
        placeholder={__('Zip')}
      />
      <FilterInput
        value={cityFilter}
        onChange={(e) => {
          const cityFilterValue = e.target.value;
          setCityFilter(cityFilterValue);
          handleFilterChange('customerAddressCity', cityFilterValue);
        }}
        type='text'
        placeholder={__('City')}
      />
    </>
  );
}

export function CommissioningReferenceFilter({
  handleFilterChange,
}: FilterChangeProps) {
  const [commissioningReferenceFilter, setCommissioningReferenceFilter] =
    useState('');

  return (
    <FilterInput
      value={commissioningReferenceFilter}
      onChange={(e) => {
        const commissioningReferenceFilterValue = e.target.value;
        setCommissioningReferenceFilter(commissioningReferenceFilterValue);
        handleFilterChange(
          'commissioningReference',
          commissioningReferenceFilterValue
        );
      }}
      type='text'
      placeholder={__('Case Id')}
    />
  );
}

const Dropdown = styled.select`
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px; // Optional: adjust font size to match your design
`;

export function InvoiceFilter({ handleFilterChange }: FilterChangeProps) {
  const [selectedValue, setSelectedValue] = useState('');

  const options = [
    { value: '', label: __('Select an option') },
    { value: 'true', label: __('yes') },
    { value: 'false', label: __('no') },
  ];

  function onFilterChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setSelectedValue(value);

    if (value === options[0].label) {
      handleFilterChange('hasInvoice', '');
    } else {
      handleFilterChange('hasInvoice', value);
    }
  }

  return (
    <Dropdown value={selectedValue} onChange={(e) => onFilterChange(e)}>
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </Dropdown>
  );
}
