/* eslint-disable no-case-declarations */
import {
  CaseRecord,
  getApiRoutes,
  getListWithHeaders as getList,
} from '@adac/core-model';
import moment from 'moment';
import { Filters, Params } from '../components/ListView';

export interface Case extends CaseRecord {
  cancelled: string;
}

export type ClosedCase = Case;
export type OpenCase = Case;

export interface GetterType<Data = Case[]> {
  data: Data;
  total: number | undefined;
}

export const dateFormat = 'DD/MM/YYYY';

// TODO: use createCaseUrl from requestCreators if possible
const buildURL = (url: string, params?: Params) => {
  if (!params) return url;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryString = (Object.entries(params) as [keyof Filters, any][]) // Ensure keys are of type keyof Filters
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return `${key}=${encodeURIComponent(JSON.stringify({ like: value }))}`;
      }

      switch (key) {
        case 'commissioningReference':
        case 'customerAddressStreet':
        case 'customerAddressCity':
        case 'customerAddressZip':
        case 'customerAddressNumber':
          return `${key}=${encodeURIComponent(JSON.stringify({ like: value }))}`;
        case 'createdAtFrom': {
          const fromDate = moment(value, dateFormat)
            .toDate()
            .setHours(0, 0, 0, 0);
          const toDate = moment(params.createdAtTo, dateFormat)
            .toDate()
            .setHours(23, 59, 59, 999);
          if (!params.createdAtTo) {
            return `${key.replace(/(From|To)$/, '')}=${JSON.stringify({ gte: fromDate })}`;
          }
          return `${key.replace(/(From|To)$/, '')}=${JSON.stringify({ between: [fromDate, toDate] })}`;
        }
        case 'createdAtTo': {
          const toDate = moment(params.createdAtTo, dateFormat)
            .toDate()
            .setHours(0, 0, 0, 0);
          if (!params.createdAtFrom) {
            return `${key.replace(/(From|To)$/, '')}=${JSON.stringify({ lte: toDate })}`;
          }
          return null;
        }
        case 'hasInvoice':
          return `${key}=${encodeURIComponent(JSON.stringify({ eq: value === 'true' }))}`;
        default:
          return `${key}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');

  return `${url}${queryString ? `?${queryString}` : ''}`;
};

export const getClosedCases = async (
  token: string,
  params?: Params
): Promise<GetterType> => {
  const url = buildURL(getApiRoutes().partner.caseList.closed, params);

  const closedCases = await getList<ClosedCase>(url, token);
  return { data: closedCases.data, total: closedCases.total };
};

export const getOpenCases = async (
  token: string,
  params?: Params
): Promise<GetterType> => {
  const url = buildURL(getApiRoutes().partner.caseList.open, params);

  const openCases = await getList<OpenCase>(url, token);
  return { data: openCases.data, total: openCases.total };
};
